<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card :loading="loading">
      <v-card-title>
        افزودن انیمیشن جدید
        <v-spacer />

        <v-btn outlined color="blue" @click="dialog = false">
          بازگشت
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" @submit.prevent="submit">
          <v-form-base :col="{ cols: 12 }" :model="model" :schema="schema" />
          <v-row align="center" justify="end" class="mt-8">
            <v-btn
              class="mx-4"
              dark
              color="success"
              :loading="loading"
              type="submit"
            >
              ثبت
            </v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { getErrorText } from "@/constants/GlobalConstants";
import { Constants } from "@/constants/GlobalConstants";
import VFormBase from "vuetify-form-base";
import { formPostPrepare } from "@/constants/PanelFormHandler";
import MyAxios from "@/constants/MyAxios";
export default {
  components: {
    VFormBase,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      model: {
        title: "",
        desc: "",
        user_id: this.$store.state.userData?.id || 0,
        media_id: "",
      },
      schema: {
        title: {
          type: "CustomInput",
          label: "عنوان",
          rules: [Constants.rules.required],
          required: true,
        },
        desc: {
          type: "CustomInput",
          label: "توضیحات",
          required: true,
        },
        media_id: {
          type: "MyFilePond",
          title: "فایل",
          fileTypes: "video/mp4",
          maxFileSize: "300MB",
          rules: [Constants.rules.required],
          required: true,
        },
      },
    };
  },
  methods: {
    showModal() {
      this.dialog = true;
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let data = formPostPrepare(this.model);

        MyAxios.post("portfolios/create", data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "انیمیشن با موفقیت ایجاد شد.",
              type: "success",
            });
            console.log(response);
            this.$emit("onFinished");
            this.dialog = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "مشکلی پیش آمده است. لطفا دوباره سعی کنید",
            });
            console.log(error);
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
  },
};
</script>

<style></style>
