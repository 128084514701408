<template>
  <v-container class="d-flex justify-center" fluid>
    <work-create-modal ref="createWorkRef" @onFinished="GetList" />
    <dialog-video v-model="videoDialog" :videoURLS="videoURLS" />
    <v-row class="mt-n13" no-gutters>
      <v-col cols="12">
        <v-card
          class="moholand-shadow rounded-lg"
          :loading="loading"
          :disabled="loading"
        >
          <v-card-title>
            انیمیشن ها
            <v-spacer></v-spacer>

            <v-btn
              v-if="items.length < 3"
              dark
              large
              class="rounded-lg mx-2 moholand-shadow my-auto"
              color="orange darken-2"
              @click="$refs.createWorkRef.showModal()"
            >
              افزودن انیمیشن جدید
            </v-btn>
          </v-card-title>

          <!-- <div class="d-flex flex-wrap pa-4">
          </div> -->

          <v-row class="pa-4" v-if="items.length">
            <v-col cols="12" md="6" v-for="(item, i) in items" :key="i">
              <div class="text-center my-2">{{ item.title }}</div>

              <div
                class="video-parent grey lighten-3"
                :class="{ grey: !item.media }"
              >
                <video width="100%" height="100%" v-if="item.media">
                  <source :src="item.media.url + '#t=0.5'" type="video/mp4" />
                </video>
                <v-overlay color="black" absolute>
                  <v-icon
                    color="white"
                    size="100"
                    class="play-button"
                    @click="item.media && playVideo(item.media)"
                  >
                    mdi-play-circle-outline
                  </v-icon>
                </v-overlay>
              </div>

              <v-btn
                dark
                block
                class="rounded-lg my-2 moholand-shadow"
                color="red darken-2"
                @click="deleteItem(item)"
              >
                حذف
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="12">
              <div class="text-center my-8">
                انیمیشنی برای شما وجود ندارد.
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MyDataTable from "../../components/MyDataTable.vue";
import TicketContentModal from "../../components/Panel/TicketContentModal.vue";
import TicketNewModal from "../../components/Panel/TicketNewModal.vue";
import WorkCreateModal from "../../components/Panel/WorkCreateModal.vue";
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import DialogVideo from "../../components/DialogVideo.vue";
export default {
  components: {
    MyDataTable,
    TicketContentModal,
    TicketNewModal,
    WorkCreateModal,
    DialogVideo,
  },
  created() {
    this.GetList();
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/portfolios/index", {
        params: {
          with: "media",
          conditions: {
            user_id: this.$store.state.userData?.id,
          },
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
        })
        .catch((error) => {
          console.log(error.response || error);
          this.loading = false;
        });
    },
    onSearchSubmit(search) {
      console.log(search);
    },
    playVideo(media) {
      if (media) {
        this.videoURLS = [
          {
            src: media.url,
            // src:
            //   "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
            // src: item.video.url,
            type: "video/mp4",
          },
        ];
        this.videoDialog = true;
      }
    },
    deleteItem(item) {
      this.loading = true;
      MyAxios.delete(`/portfolios/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
          this.loading = false;
        });
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      videoURLS: "",
      videoDialog: false,
      headers: [
        {
          text: "عنوان تیکت",
          align: "start",
          value: "title",
          groupable: false,
          sortable: false,
        },
        {
          text: "وضعیت",
          align: "start",
          value: "file_name",
          groupable: false,
          sortable: false,
        },
        {
          text: "عملیات",
          value: "actions",
          groupable: false,
          sortable: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.video-parent {
  height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden !important;
}
.play-button {
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  position: absolute;
}
</style>
